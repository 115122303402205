<template>
  <svg width="48" height="48" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
    <circle cx="64.191" cy="64" fill="#3ba9fd" r="12"></circle>
    <path
      d="m67.205 70.938a1.75 1.75 0 0 1 -.882-3.262 4.275 4.275 0 0 0 2.118-3.676 1.75 1.75 0 0 1 3.5 0 7.783 7.783 0 0 1 -3.856 6.7 1.734 1.734 0 0 1 -.88.238z"
      fill="#0e8bce"
    ></path>
    <path
      d="m64.191 123.25c-14.791 0-26.377-26.025-26.377-59.25s11.586-59.25 26.377-59.25 26.378 26.025 26.378 59.25-11.587 59.25-26.378 59.25zm0-115c-12.401 0-22.877 25.53-22.877 55.75s10.476 55.75 22.877 55.75 22.878-25.53 22.878-55.75-10.477-55.75-22.878-55.75z"
      fill="#eaeaf0"
    ></path>
    <path
      d="m31.6 101.569c-8.9 0-15.635-2.594-18.724-7.944-7.392-12.809 9.354-35.855 38.124-52.469 28.773-16.612 57.1-19.593 64.5-6.781s-9.347 35.855-38.12 52.469c-16.758 9.675-33.367 14.725-45.78 14.725zm21.15-57.381c-26.168 15.112-43.04 36.948-36.839 47.687s33.548 7.048 59.719-8.062 43.042-36.949 36.842-47.688-33.548-7.048-59.719 8.063z"
      fill="#eaeaf0"
    ></path>
    <path
      d="m96.78 101.569c-12.41 0-29.018-5.049-45.777-14.725-28.773-16.614-45.519-39.66-38.124-52.469s35.728-9.83 64.5 6.781 45.521 39.66 38.121 52.469c-3.085 5.349-9.824 7.944-18.72 7.944zm-44.027-17.756c26.171 15.11 53.518 18.8 59.719 8.062s-10.672-32.575-36.842-47.687-53.518-18.8-59.719-8.063 10.671 32.575 36.842 47.688z"
      fill="#eaeaf0"
    ></path>
    <g fill="#3ba9fd">
      <circle cx="111.375" cy="52.419" r="6"></circle>
      <circle cx="78.191" cy="111.035" r="6"></circle>
      <circle cx="16.625" cy="52.419" r="6"></circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AtomLogo"
};
</script>